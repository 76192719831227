import { FC } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import WhatsApp from "shared/WhatsApp/WhatsApp";
import Navigation from "shared/Navigation/Navigation";

export interface MainNavProps { }

const MainNav: FC<MainNavProps> = () => {

  const renderContent = () => {
    return (
      <div className="flex justify-between h-20">
        <div className="flex items-center lg:hidden">
          <MenuBar />
        </div>

        <div className="flex items-center">
          <Logo className="flex-shrink-0" />
        </div>

        <div className="flex items-center hidden lg:block">
          <Navigation />
        </div>

        <div className="flex items-center">
          <WhatsApp />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="h-10 hidden lg:block bg-gradient-to-r from-warmPink via-deepFuchsia to-cornflower">
        <div className="container h-full">
          <div className="h-full flex justify-between items-center">
            <div className="text-white font-bold">משלוחים מהירים לכל חלקי הארץ</div>
            <div className="text-white font-bold flex gap-2">
              <span>כתובתינו:</span>
              <a
                className="hover:text-cornflower"
                href="https://ul.waze.com/ul?place=ChIJMd5a30a0AhURAMhOvJdJjGo&ll=31.96721820%2C34.80266480&navigate=yes"
                target="_blank"
                rel="noopener noreferrer"
              >
                הרצל 46, ראשל״צ
              </a>
              <span>|</span>
              <span>טל’ להזמנות:</span>
              <a
                className="hover:text-cornflower"
                href="tel:03-9691656"
                target="_blank"
                rel="noopener noreferrer"
              >
                03-9691656
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="nc-MainNav2Logged relative z-10 bg-white bg-gradient-to-r from-warmPink via-deepFuchsia to-cornflower lg:bg-white lg:from-transparent lg:via-transparent lg:to-transparent dark:bg-neutral-900 border-b border-slate-100 dark:border-slate-700">
        <div className="container ">{renderContent()}</div>
      </div>
    </>
  );
};

export default MainNav;
