import { FC, useEffect, useId } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import BrandCard from "./BrandCard";
import hpLogo from "images/brands/hp.png";
import okiLogo from "images/brands/oki.png";
import dellLogo from "images/brands/dell.png";
import canonLogo from "images/brands/canon.png";
import epsonLogo from "images/brands/epson.png";
import richoLogo from "images/brands/richo.png";
import sharpLogo from "images/brands/sharp.png";
import xeroxLogo from "images/brands/xerox.png";
import philipsLogo from "images/brands/philips.png";
import brotherLogo from "images/brands/brother.png";
import samsungLogo from "images/brands/samsung.png";
import lexmarkLogo from "images/brands/lexmark.png";
import logitechLogo from "images/brands/logitech.png";

export interface CardBrandData {
  name: string;
  img: string;
  color?: string;
}

const BRANDS: CardBrandData[] = [
  {
    name: "HP",
    img: hpLogo,
    color: "bg-white",
  },
  {
    name: "brother",
    img: brotherLogo,
    color: "bg-white",
  },
  {
    name: "EPSON",
    img: epsonLogo,
    color: "bg-white",
  },
  {
    name: "xerox",
    img: xeroxLogo,
    color: "bg-white",
  },
  {
    name: "Canon",
    img: canonLogo,
    color: "bg-white",
  },
  {
    name: "Logitech",
    img: logitechLogo,
    color: "bg-white",
  },
  {
    name: "DELL",
    img: dellLogo,
    color: "bg-white",
  },
  {
    name: "PHILIPS",
    img: philipsLogo,
    color: "bg-white",
  },
  {
    name: "SAMSUNG",
    img: samsungLogo,
    color: "bg-white",
  },
  {
    name: "SHARP",
    img: sharpLogo,
    color: "bg-white",
  },
  {
    name: "RICHO",
    img: richoLogo,
    color: "bg-white",
  },
  {
    name: "OKI",
    img: okiLogo,
    color: "bg-white",
  },
  {
    name: "Lexmark",
    img: lexmarkLogo,
    color: "bg-white",
  },
];

export interface SectionSliderBrandsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardBrandData[];
}

const SectionSliderBrands: FC<SectionSliderBrandsProps> = ({
  heading = "המותגים שלנו",
  subHeading = "",
  className = "",
  itemClassName = "",
  data = BRANDS,
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 8,
      gap: 32,
      bound: true,
      direction: 'rtl',
      breakpoints: {
        1280: {
          perView: 4,
        },
        1024: {
          gap: 20,
          perView: 3.4,
        },
        768: {
          gap: 20,
          perView: 3.3,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [UNIQUE_CLASS]);

  return (
    <div className={`nc-SectionSliderCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading desc={subHeading} hasNextPrev id={"brands"}>
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {data.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <BrandCard
                  name={item.name}
                  featuredImage={item.img}
                  bgClass={item.color}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderBrands;
