import { FC } from "react";
import Button from "shared/Button/Button";
import heroPrinter from "images/hero-printer.png";
import circleShape from "images/backgrounds/circle-shape.png";
import iconPrinter from "images/icons/icon-printer.svg";
import iconCheckmark from "images/icons/icon-checkmark.svg";
import iconPhone from "images/icons/icon-phone.svg";
import iconWhatsApp from "images/icons/icon-whatsapp.svg";

export interface SectionHeroTopProps {
  className?: string;
}

const SectionHeroTop: FC<SectionHeroTopProps> = () => {

  const handleCall = () => {
    window.location.href = "tel:039691656";
  };

  const handleWhatsApp = () => {
    window.open("https://wa.me/972526250707");
  };

  return (
    <div className="bg-pink-50" >
      <img className="absolute top-0 left-0 min-w-fit z-10" src={circleShape} alt="Circle shape" />
      <div className="container py-10">

        <div className="px-0 lg:px-10 flex justify-between items-center gap-12 flex-col lg:flex-row flex-wrap">

          <div className="flex-col justify-start items-center lg:items-start gap-[30px] inline-flex z-30">

            <div className="px-[15px] py-2.5 bg-white rounded-[50px] shadow justify-center items-center gap-2.5 inline-flex">
              <img src={iconPrinter} alt="Printer" />
              <div className="text-center text-slate-900 text-base font-normal">
                להדפיס בלי לקרוע את הכיס
              </div>
            </div>

            <div className="text-slate-900 font-bold text-center lg:text-right text-[42px] lg:text-[64px] leading-[50px] lg:leading-[70px]">
              <span>דיו וטונר </span>
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-warmPink via-deepFuchsia to-cornflower">למדפסות <br /></span>
              <span>עד לבית ולמשרד</span>
            </div>

            <div className="flex flex-col gap-[20px] self-start">
              <div className="inline-flex gap-[15px]">
                <img src={iconCheckmark} alt="Checkmark" />
                <div className="text-slate-900 text-xl font-normal flex gap-2">
                  <span>בקרו אותנו בחנות,</span>
                  <a
                    className="hover:text-cornflower underline"
                    href="https://ul.waze.com/ul?place=ChIJMd5a30a0AhURAMhOvJdJjGo&ll=31.96721820%2C34.80266480&navigate=yes"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    הרצל 46, ראשל״צ
                  </a>
                </div>
              </div>
              <div className="inline-flex gap-[15px]">
                <img src={iconCheckmark} alt="Checkmark" />
                <div className="text-slate-900 text-xl font-normal">
                  משלוחים מהירים למשרד ולבית
                </div>
              </div>
              <div className="inline-flex gap-[15px]">
                <img src={iconCheckmark} alt="Checkmark" />
                <div className="text-slate-900 text-xl font-normal">
                  מחירים נוחים לכל כיס
                </div>
              </div>
            </div>

            <div className="w-full lg:w-[605px] flex flex-col lg:flex-row min-h-fit justify-between items-center gap-[22px]">
              <Button
                onClick={handleCall}
                className="!h-[47px] !py-2.5 !px-10 bg-white rounded-[50px] border-b-4 border-pink-500 justify-center items-center gap-2.5 flex">
                <div className="flex justify-center items-center gap-2.5">
                  <img src={iconPhone} alt="Phone" />
                  <div className="text-center text-slate-900 text-xl font-bold">הזמינו טלפונית</div>
                </div>
              </Button>
              <div className="flex items-center gap-[7px]">
                <div className="w-2.5 h-px bg-zinc-400" />
                <div className="text-center text-slate-900 text-xl font-normal">או</div>
                <div className="w-2.5 h-px bg-zinc-400" />
              </div>
              <Button
                onClick={handleWhatsApp}
                className="!h-[47px] !py-2.5 !px-10 bg-green-500 rounded-[50px] border-b-4 border-neutral-300 justify-center items-center gap-2.5 flex">
                <div className="flex justify-center items-center gap-2.5">
                  <img src={iconWhatsApp} alt="WhatsApp" />
                  <div className="text-center text-white text-xl font-bold">הזמינו בווטסאפ</div>
                </div>
              </Button>
            </div>
          </div>

          <div className="ml-[-10%] lg:ml-[unset]">
            <div className="w-[300px] h-[300px] lg:w-[400px] lg:h-[400px] relative" >
              <div className="absolute bottom-0 -right-7 w-[260px] h-[260px] lg:w-[360px] lg:h-[360px] bg-gradient-to-r from-pink-500 via-fuchsia-600 to-indigo-500 rounded-full" />
              <div className="absolute w-full h-full bg-white rounded-full" />
              <img className="absolute p-8 z-20" src={heroPrinter} alt="Printer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHeroTop;
