import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";

export interface BrandCardProps {
    ratioClass?: string;
    bgClass?: string;
    name?: string;
    featuredImage?: string;
}

const BrandCard: FC<BrandCardProps> = ({
    ratioClass,
    bgClass = "bg-orange-50",
    name,
    featuredImage = ".",
}) => {
    return (
        <div
            className={`drop-shadow-light mb-8 flex-1 relative w-full rounded-tr-2xl rounded-bl-2xl border-[1px] overflow-hidden group ${ratioClass} ${bgClass}`}
        >
            <NcImage
                containerClassName="w-full h-[70px] flex justify-center"
                src={featuredImage}
                alt={name}
                className="object-scale-down p-4"
            />
        </div>
    );
};

export default BrandCard;
