import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import SectionHeroTop from "./SectionHeroTop";
import SectionAboutUs from "./SectionAboutUs";
import SectionContactUs from "./SectionContactUs";
import SectionOurService from "./SectionOurService";
import SectionSliderBrands from "components/SectionSliderBrands/SectionSliderBrands";
import useWindowSize from 'hooks/useWindowResize';

function PageHome() {

    const location = useLocation();
    const { width } = useWindowSize();

    const isMobile = useMemo(() => width < 765, [width]);

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                console.log('elementPosition', elementPosition, location.hash);
                const offsetPosition = elementPosition - (isMobile ? 150 : 150);

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [location]);

    return (
        <div className="nc-PageHome relative overflow-hidden">

            <SectionHeroTop />

            {/* <div className="mt-24 lg:mt-32">
                <DiscoverMoreSlider />
            </div> */}

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
                <SectionSliderBrands />
                <SectionOurService />
            </div>

            <SectionContactUs />

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
                <SectionAboutUs />
            </div>
        </div>
    );
}

export default PageHome;
