import { FC } from "react";
import Heading from "components/Heading/Heading";

import deliveryIcon from '../../images/our-service/our-service-delivery-icon.svg';
import heartIcon from '../../images/our-service/our-service-heart-icon.svg';
import printerIcon from '../../images/our-service/our-service-printer-icon.svg';
import mainImage from '../../images/our-service/our-service-main-combined.png';

export interface ServiceItem {
  id: string;
  title: string;
  description: string;
  icon: string;
}

const SERVICE_ITEMS: ServiceItem[] = [
  {
    id: "1",
    title: "משלוחים מהירים",
    description: "אנו מציעים משלוח מהיר ואמין שיענה על צרכי הלקוחות בזמן קצר ובנוחות, עם אפשרות לשליחה ישירות לבית הלקוח",
    icon: deliveryIcon,
  },
  {
    id: "2",
    title: "אדיבות",
    description: "צוות העובדים שלנו מתעניין בכל לקוח ומקפיד על שירות אדיב, ידידותי ואישי",
    icon: heartIcon,
  },
  {
    id: "3",
    title: "מקצועיות",
    description: "אנו מספקים ייעוץ מקצועי ותמיכה מלאה ללקוחותינו, עם התמחות בתחום הדיו, המדפסות והטכנולוגיה המחשובית מעל 18 שנה",
    icon: printerIcon,
  },
];

export interface SectionOurServiceProps {
  className?: string;
};

interface ServiceItemRowProps {
  item: ServiceItem;
};

const ServiceItemRow = ({ item }: ServiceItemRowProps) => {
  return (
    <div className="px-5 py-2.5 bg-white inline-flex gap-8 rounded-xl border border-transparent hover:border-[#5b9cd8]/30 hover:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.05)]">
      <img className="w-16" src={item.icon} alt={item.title} />
      <div>
        <div className="text-[#09052f] font-bold text-lg">{item.title}</div>
        <div className="text-[#5f5f5f] font-normal">{item.description}</div>
      </div>
    </div>
  );
};

const SectionOurService: FC<SectionOurServiceProps> = ({ className = "" }) => {
  return (
    <div className={`nc-SectionOurService relative ${className}`}>
      <Heading
        id={"our-service"}
        desc="השירות שלנו כולל התקנה ותיקון של מחשבים ומדפסות במקצועיות ובמהירות, כך שתוכלו להמשיך לעבוד ביעילות ובביטחון"
      >
        השירות שלנו
      </Heading>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        <div className="flex flex-col gap-8">
          {SERVICE_ITEMS.map((item) => (
            <ServiceItemRow key={item.id} item={item} />
          ))}
        </div>
        <img className="w-full max-w-lg justify-self-center" src={mainImage} alt="Our Service" />
      </div>


      {/* <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className={`p-6 ${item.backgroundColor} dark:bg-neutral-800 rounded-2xl dark:border-neutral-800`}
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default SectionOurService;
