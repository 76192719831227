import { FC } from "react";
import aboutUsImage from "images/about-us/about-us-main.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionAboutUsProps {
  className?: string;
  btnText?: string;
}

const SectionAboutUs: FC<SectionAboutUsProps> = ({
  className = "",
  btnText,
}) => {
  return (
    <div className={`nc-SectionHero relative ${className}`} data-nc-id="SectionHero">
      <div className="flex flex-col gap-10 lg:flex-row space-y-14 lg:space-y-0 ltr:lg:space-x-10 items-center relative rtl:text-right ltr:text-left">
        <div className="w-screen max-w-full space-y-5 lg:space-y-7">
          <h2 id={"about"} className="text-3xl font-semibold text-neutral-900 md:text-4xl dark:text-neutral-100">
            הכירו אותנו
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            <p className="mb-5">
              חברת MYDYO (תקשיר בע״מ), הוקמה בשנת 2006 ומתמחה בשיווק
              חומרים מתכלים לכל סוגי המדפסות והפקסים,
              מחסניות דיו וטונר מקוריים וחליפיים, ציוד היקפי לסביבת המחשב
              ומחשבים ניידים, נייחים ותחנות עבודה.
            </p>
            <p className="mb-5">
              קהל לקוחותינו מורכב מהמגזר הפרטי והעסקי, בין לקוחותינו נמנים
              משרדי עו״ד, רו״ח, מרפאות ומוסדות חינוך וכמובן לקוחות פרטיים.
              MYDYO מציעה לכלל לקוחותיה הוזלה משמעותית בעלויות ההדפסה,
              וזאת מבלי לפגוע באיכות ההדפסה או במדפסת- להדפיס מבלי לקרוע את הכיס.
              אנו שמים דגש מיוחד על איכות מוצרינו, ולכן כל מוצר המסופק ללקוח
              נבדק בקפידה.
            </p>
            <p className="mb-5">
              MYDYO חרטה על דגלה את המצוינות בשירות והבנת הצרכים של כל
              לקוח ולקוח. במהלך השנים צברה ידע רב בעולם הדיו, טונרים מדפסות
              ומחשבים.
            </p>
            <p className="mb-5 font-bold">
              בנוסף, MYDYO מעניקה שירות טכנאי למדפסות ומחשבים באתר הלקוח.
            </p>
          </span>
          {!!btnText && <ButtonPrimary href="/login">{btnText}</ButtonPrimary>}
        </div>
        <div className="flex-grow max-w-[40rem]">
          <img className="w-full" src={aboutUsImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionAboutUs;
