import React from "react";
import whatsapp from "images/whatsapp-icon.png"

export interface WhatsAppProps {
    className?: string;
}

const WhatsApp: React.FC<WhatsAppProps> = ({ className = "" }) => {

    const hadleWhatsAppClick = () => {
        window.open("https://wa.me/972526250707");
    }

    return (
        <button
            onClick={hadleWhatsAppClick}
            className={`text-2xl md:text-3xl w-10 h-10 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center ${className}`}
        >
            <span className="sr-only">WhatsApp</span>
            <img className="w-full" src={whatsapp} alt="WhatsApp" />
        </button>
    );
};

export default WhatsApp;
