import { Helmet } from "react-helmet-async";

const PageTerms = () => {

  return (
    <div className="nc-PageTerms pt-8 lg:pt-16 ">
      <Helmet>
        <title>תקנון ותנאי שימוש</title>
      </Helmet>

      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            תקנון ותנאי שימוש
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            תקשיר בע"מ, ח.פ 510944333, הרצל 46 ראשון לציון, טל' 03-9691656
          </span>
        </div>
      </header>

      <div className="container rounded-xl">
        <div className="prose prose-sm sm:prose lg:prose-lg max-w-screen-md mx-auto mt-5 mb-14">

          <p>אתר MYDYO המופעל ע"י חברת תקשיר בע"מ ומשמש גם בתור אתר מכירות לרכישת מתכלים למדפסות, מדפסות, פקסים, ציוד היקפי ומחשבים.</p>
          <p>האמור בתקנון זה בלשון זכר הוא לשם הנוחות בלבד והתקנון מתייחס לבני שני המינים באופן שווה הרכישה באמצעות האתר כפופה לתנאים המפורטים בתקנון זה עצם רכישת המוצר באתר תהווה הצהרה מצד הלקוח כי קרא את הוראות תקנון זה, הבין אותן והסכים להן. התקנון מהווה חוזה מחייב בינך לבין החברה.</p>

          <h3>הרכישה</h3>
          <ul>
            <li>האתר מאפשר ללקוחות קנייה נוחה, קלה ובטוחה של המוצר באמצעות האינטרנט. לאחר שביצעת את ההזמנה תקבל הודעה ראשונית על פרטי קליטת הזמנתך. לתשומת ליבך אישור זה אינו מחייב את החברה לספק את המוצרים שהזמנת והוא רק מעיד שפרטי ההזמנה נקלטו במערכת.רק לאחר שהחברה תסיים לבדוק את פרטי כרטיס האשראי שלך ויתקבל אישור מחברת האשראי לביצוע הרכישה תיחשב הצעתך כברת ביצוע ויתחילו כל תהליכי אספקת המוצרים שהזמנת  שהם מצויים במלאי החברה או יצרניהם או מפיציהם בישראל (ייתכנו מקרים בהם בשל נסיבות חריגות שלא ניתן היה לדעת בעת ביצוע ההזמנה, המוצר לא יהיה במלאי ובמקרה זה תימסר הודעה לצרכן).</li>
            <li>הרכישה באתר הינה באמצעות כרטיס אשראי בלבד והעסקה תתבצע לאחר אישורה ע"י חברת -האשראי.</li>
            <li>מחיר המוצר באתר כולל את המע"מ.</li>
            <li>החברה שומרת לעצמה את הזכות להפסיק את שיווק ומכירת המוצר בכל עת וכן לשלול זכות רכישה באתר מכירות על פי שיקול דעתה. סיבות שונות עלולות לגרום לתקלות ולשיבושים בקליטת הזמנתך ועיבודה על ידי החברה. אם יחול שיבוש ו/או טעות חריגה או בתם לב בתיאור המוצר בתמונתם, תיאורם, מחירם או בכל פרט אחר, תהא החברה רשאית לבטל את רכישתך ולא תחויב בכל תשלום בגין ההזמנה שבוטלה. עם זאת, עלול להיווצר מצב, בתום לב, ובו לאחר תום הליך המכירה, יתברר כי המוצר חסר מן המלאי של הספק או כי השירות איננו זמין. במצב כזה, הספק יציע לקונה מוצר/שירות חלופי, הדומה בתכונותיו ובמחירו למוצר הנבחר, בפנייה ישירה לקונה באמצעות הטלפון או הדואר האלקטרוני שלו. במידה ואין הקונה מעוניין במוצר/בשירות החלופי, קמה לו הזכות לסרב לקבלו. במקרה כזה, תבוטל הזמנת המוצר/השירות וכספו של הקונה, ככל ששולם, יוחזר לו.</li>
            <li>הגשת פרטים אישיים כוזבים אסורה לחלוטין ומהווה עבירה פלילית על פי הוראות חוק העונשין, התשל"ז- 1977 ו/או בכל דין.</li>
          </ul>

          <h3>החזרות וביטולים</h3>
          <ul>
            <li>ניתן לבטל את העסקה באמצעות פניה טלפונית או בדואר אלקטרוני לשירות הלקוחות של החברה.</li>
            <li>ביטול העסקה יהיה בתוקף אך ורק לאחר קבלת פקס או דואר אלקטרוני מהחברה המאשר את הבקשה לביטול העסקה. במקרה שהביטול אושר – יש להשיב את המוצר לחברה כאשר כל העלויות הכרוכות בהחזרת המוצר תחולנה על הלקוח. החזרת המוצר תיעשה כשהוא באריזתו המקורית בצירוף החשבונית המקורית ושעדיין לא חלפו 14 יום מתאריך רכישת המוצר.</li>
            <li>בעת ביטול עסקה שלא עקב פגם או אי התאמה בין המוצר לחשבונית ייגבו דמי ביטול בשיעור של 5% או 100 ש"ח לפי הנמוך מביניהם. בעת ביטול עסקה שלא עקב פגם או אי התאמה הלקוח יצטרך להחזיר את המוצר/ים לכתובת הרצל 46, ראשון לציון, כאשר הוצאות המשלוח כתובתנו חלות על הקונה.</li>
            <li>בעת ביטול עסקה עקב פגם או אי התאמה בין המוצר לחשבונית, או כל הפרה אחרת של החוזה, לא ייגבו דמי ביטול. כספו של הצרכן יוחזר לו תוך 14 ימי עסקים מקבלת הודעת הביטול. המוצר יוחזר על חשבון החברה.</li>
            <li>המוצר יוחזר, ככל שהדבר אפשרי או סביר, באריזתו המקורית. </li>
            <li>לא ניתן לבטל עסקה במקרים הבאים: טובין פסידין, מוצרים שניתנים לשכפול וההעתקה במקרה שהלקוח פתח את האריזה המקורית.</li>
            <li>הודעה על הביטול תימסר דרך הטלפון, פקס, דואר ישראל ודואר אלקטרוני בלבד.</li>
          </ul>


          <h3>אספקה והובלת המוצר</h3>
          <ul>
            <li>החברה תדאג לאספקת המוצר ללקוח לכתובת שהוקלדה על ידו בעת ביצוע הרכישה באתר מכירות, ע"פ סוג המשלוח שבחר.</li>
            <li>החברה לא תהא אחראית לכל איחור ו/או עיכוב באספקה ו/או אי-אספקה, שנגרמה כתוצאה מכוח  עליון ו/או מאירועים שאינם בשליטתה. החברה עושה את מירב המאמצים שהמוצרים יסופקו ללא איחור ללקוח לעיתים קורה שהאיחור הנו בעקבות הספק/הלקוח לכן יש להבהיר שלא יינתן כל פיצוי עבור איחור המשלוח. </li>
            <li>משלוח המוצר יבוצע לכתובת שנמסרה ע"י הלקוח בעת הרכישה או שהלקוח רשאי לאסוף את המוצר באופן עצמאי מבית העסק, בתיאום מראש על מנת לוודא כי המוצר במלאי. </li>
            <li>מחיר המוצר אינו כולל את עלות המשלוח.</li>
            <li>משלוחים מעבר לקו הירוק ומשלוחים לאילת מותנים בתוספת תשלום על פי תמחור חברת השילוח עמה עובד האתר. </li>
          </ul>

          <h3>שירות לקוחות</h3>
          <p>בכל שאלה או בירור לגבי המוצר, ניתן לפנות אל נציגי השירות של החברה בטלפון 03-9691656</p>

          <h3>אחריות ושירות</h3>
          <ul>
            <li>החברה ו/או מי מטעמו אינם מייצרים את המוצרים, ואינם נושאים באחריות לטיב המוצרים, לתכונותיהם, לשמות היצרנים ו/או לכל מצג שנעשה באתר בכל הנוגע לפרטים האמורים .</li>
            <li>כל התמונות באתר הנם להמחשה בלבד, לעתים יתכנו טעויות צילום ו/או הפרשי גוונים בין תצלומי המוצרים המתפרסמים באתר לבין הגוונים המדוייקים של המוצרים כפי שיסופקו בפועל. </li>
            <li>האחריות הבלעדית למוצרים, לאספקתם, לאיכותם, לטיבם, לעמידותם ולתכולתם, לרבות הוראות הפעלה בשפה העברית, או כל שפה אחרת חלה על היצרנים ו/או היבואנים של המוצרים ובהתאם לתנאים המופיעים על תעודת האחריות שמצורפת אליהם.יחד עם זאת החברה תפנה את הלקוח למוקדי התמיכה של היבואן, הספק או היצרן. אלא אם כן הלקוח רכש מוצר באחריות מורחבת של חברת MYDYO .יחד עם זאת החברה תנקוט בכל האמצעים כדי לפתור את הבעיות שנוצרו ותאפשר לבטל עסקה. בשום נסיבות לא תחול על החברה ו/או על מי מטעמה אחריות כלשהי בגין נזק ישיר, עקיף, דמי נזיקין עונשיים,נזק מקרי,מיוחד ו/או כל נזק אחר מכל סוג ומין, בכלליות האמור לעיל, לרבות ומבלי לפגוע ואו לדרוש דמי נזיקין בגין אובדן השימוש, אובדן נתונים או אובדן רווחים, הנובעים או קשורים בכל דרך שהיא בשימוש או בביצועי האתר, בגין עיכוב בשימוש או באי יכולת להשתמש במוצרים, באספקתם או באי אספקתם של מוצרים או שירותים, או בכל מידע, תוכנה, מוצר,שירות וגרפיקה נלווית שהושגו באמצעות האתר, ו/או הנובעים בכל דרך אחרת מן השימוש באתר, בין בהתבסס על הסכם ובין בנזיקין,בין באחריות מוחלטת, ו/או כל עילה אחרת, אפילו אם נודע לחברה ו/או מי מטעמה על האפשרות של דמי נזיקין ויתאפשר ללקוח לבטל את העסקה  ללא גביית דמי ביטול . </li>
            <li>החברה ו/או מי מטעמו לא יישאו בכל אחריות לכל שימוש בלתי מורשה,שימוש לרעה,שימוש שלא כדין ו/או שימוש במרמה שייעשה על ידי צד שלישי בכרטיס האשראי בו עשית שימוש לשם ביצוע פעולה באתר.</li>
            <li>לא תינתן אחריות על התקנה שהתבצעה על ידי הלקוח. במידה ובחרת לבצע התקנה כלשהי שלא מטעם החברה או ע"י בעל בר סמכא בתחום הרלוונטי תפוג האחריות למוצר הנרכש, מקום שיש בהתקנה לגרום נזק למוצר/לתוכנה.</li>
            <li>הפרטים האישיים שמסרת בעת ההזמנה,כמו גם פרטי הרכישות שתבצע בעתיד,יישמרו במאגר למעט פרטי כרטיס האשראי שמסרת לחברה לגבי העסקה שבצעת באתר זה. החברה מתחייבת לא להעביר את פרטיך האישיים לאף גורם אחר זולת לספקים וזאת רק על מנת להשלים את פעולות הרכישה שביצעת באתר. למרות שעל פי חוק אינך חייב למסור לנו את פרטיך האישיים, לא נוכל לטפל בהזמנתך בהעדרם. </li>
            <li>החברה נוקטת באמצעי זהירות מקובלים על מנת לשמור, ככל האפשר, על סודיות המידע. במקרים שאינם בשליטת החברה ו/או הנובעים מכוח עליון, לא תהא החברה אחראית לכל נזק מכל סוג שהוא, ישר או עקיף, שייגרם ללקוח, אם מידע זה יאבד ו/או יעשה בו שימוש לא מורשה. כוח עליון משמעו לרבות תקלות מחשב, תקלות במערכת טלפונים או תקלות במערכות תקשורת אחרות, חבלה כלשהי ואירוע בטחוני. החברה שומרת לעצמה את הזכות לסגור את האתר ו/או לבטל מכירה על פי שיקול דעתה באם יתברר לה שהתבצעה או מתבצעת באתר פעילות בלתי חוקית או נגרם נזק כלשהו כתוצאה מתקלה טכנית באתר.</li>
          </ul>

          <h3>אבטחת מידע ופרטיות</h3>
          <p>כפתור "שלח" יש משום הסכמה לחברת MYDYO או למי שנבחר מטעמה לעשות שימוש במידע אישי זה על מנת לספק לך מידע על המוצרים ומידע נוסף באמצעות אימייל או אחר, על שירותים ומשאבים הקשורים למוצרים של החברה. בנוסף, ניתן יהיה להשתמש במידע אישי זה למטרות סקר שוק. באם תחפצן להסיר בכל עת את פרטייך האישיים מרשימת התפוצה של החברה התקשרו לטלפון  03-9691656. באמצעות לחיצה על כפתור "שלח" אני מאשר בחתימתי את הטופס ואת תנאיו.</p>

          <h3>קניין רוחני</h3>
          <ul>
            <li>כל זכויות הקניין הרוחני באתר מכירות זה הינן רכושה הבלעדי של חברת MYDYO</li>
            <li>אין להעתיק, לשכפל, להפיץ, לפרסם או להשתמש בכל דרך אחרת במידע כלשהו מן האתר ו/או מאתר מכירות זה, אלא אם ניתנה הסכמה לכך מראש ובכתב מטעם החברה.</li>
          </ul>

          <h3>דין וסמכות שיפוט</h3>
          <p>פרשנותו ואכיפתו של תקנון זה ו/או כל פעולה או סכסוך הנובע ממנו יעשו בהתאם לדין הישראלי בלבד ולבית המשפט המוסמך באחד מהמחוזות תל אביב-יפו, חיפה, ירושלים או ב"ש תהא מסורה סמכות השיפוט הבלעדית.</p>
        </div>
      </div>
    </div>
  );
};

export default PageTerms;
