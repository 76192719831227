import { FC } from "react";

import backgroundImage from '../../images/contact-us/contact-us-background.png';
import iconAddress from '../../images/contact-us/contact-us-home-icon.svg';
import iconPhone from '../../images/contact-us/contact-us-phone-icon.svg';
import iconFax from '../../images/contact-us/contact-us-fax-icon.svg';
import iconEmail from '../../images/contact-us/contact-us-email-icon.svg';
import MobileContactUs from "components/ContactUs/MobileContactUs/MobileContactUs";
import DesktopContactUs from "components/ContactUs/DesktopContactUs/DesktopContactUs";

export interface SectionContactUsProps {

}

const info = [
  {
    title: "כתובת",
    description: "הרצל 46, ראשון לציון",
    href: "https://ul.waze.com/ul?place=ChIJMd5a30a0AhURAMhOvJdJjGo&ll=31.96721820%2C34.80266480&navigate=yes",
    targetBlank: true,
    icon: iconAddress,
  },
  {
    title: "טלפון",
    description: "03-9691656",
    href: "tel:03-9691656",
    icon: iconPhone,
  },
  {
    title: "פקס",
    description: "03-5233856",
    href: "tel:03-5323856",
    icon: iconFax,
  },
  {
    title: "דוא״ל",
    description: "info@mydyo.co.il",
    href: "mailto:info@mydyo.co.il",
    icon: iconEmail,
  },
];

const SectionContactUs: FC<SectionContactUsProps> = () => {
  return (
    <>
      <div
        id="contact-us"
        className="bg-cover bg-center bg-no-repeat w-full"
        style={{ backgroundImage: `url('${backgroundImage}')` }}>
        <div className="bg-black bg-opacity-70 h-full p-14 pb-40 lg:pb-14">

          <div className="container max-w-7xl mx-auto">
            <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">

              <div className="lg:max-w-sm space-y-8 text-white my-auto">
                <div className="text-center lg:text-right text-[32px] font-semibold">יצירת קשר</div>
                <div className="text-center lg:text-right text-lg font-normal">כל הדרכים ליצירת קשר איתנו</div>
                {info.map((item, index) => (
                  <div className="flex items-center gap-3" key={index}>
                    <img src={item.icon} alt={item.title} />
                    <div>
                      <h3 className="uppercase font-bold text-xl tracking-wider">
                        {item.title}
                      </h3>
                      <a href={item.href} target={item.targetBlank ? "_blank" : undefined}>
                        <span className="text-lg block mt-2">
                          {item.description}
                        </span>
                      </a>
                    </div>
                  </div>
                ))}
              </div>

              <DesktopContactUs className="hidden lg:block" />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-12 sm:p-12 -mt-[160px]">
        <MobileContactUs className="block lg:hidden" />
      </div>
    </>
  );
};

export default SectionContactUs;
