import { useState, useCallback } from "react";
import env from "utils/env";
import emailjs from "@emailjs/browser";

interface FormValues {
    fullName: string;
    email: string;
    description: string;
}

interface Errors {
    fullName?: string;
    email?: string;
    description?: string;
}

const initialValues: FormValues = {
    fullName: "",
    email: "",
    description: "",
};

const submittedMessageDuration = 6000;

const emailOptions = {
    publicKey: env.emailJSPublicKey,
    // Do not allow headless browsers
    blockHeadless: true,
    blockList: {
        // Block the suspended emails
        list: ['foo@emailjs.com', 'bar@emailjs.com'],
        // The variable contains the email address
        watchVariable: 'email',
    },
    limitRate: {
        // Set the limit rate for the application
        id: 'app',
        // Allow 1 request per 30s
        throttle: 30000,
    },
}

const useContactForm = () => {

    const [values, setValues] = useState<FormValues>(initialValues);
    const [errors, setErrors] = useState<Errors>({});
    const [isSubmitting, setSubmitting] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);

    const validate = useCallback((): boolean => {
        let tempErrors: Errors = {};

        if (!values.fullName.trim()) {
            tempErrors.fullName = "שדה חובה";
        }
        if (!values.email.trim()) {
            tempErrors.email = "שדה חובה";
        } else if (!/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(values.email)) {
            tempErrors.email = "יש להזין כתובת דוא״ל תקינה";
        }
        if (!values.description.trim()) {
            tempErrors.description = "שדה חובה";
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    }, [values]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setValues((prev) => ({ ...prev, [name]: value }));

        // Dismiss error on focus
        setErrors((prev) => ({ ...prev, [name]: "" }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate()) return;

        try {
            setSubmitting(true);
            await sendEmail(values);
            setSubmitting(false);
            setSubmitted(true);
            setValues(initialValues);
            setTimeout(() => setSubmitted(false), submittedMessageDuration);
        } catch (error) {
            console.error("Error submitting contact us form:", error);
        }
    };

    const sendEmail = async (data: FormValues) => {
        try {
            await emailjs.send(env.emailJSServiceId, env.emailJSTemplateId, { ...data }, emailOptions);
        } catch (error) {
            console.error("Error sending email:", error);
        }
    };

    return { values, errors, isSubmitting, isSubmitted, handleChange, handleSubmit };
};

export default useContactForm;