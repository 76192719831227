import React from "react";
import { Link } from "react-router-dom";
import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "דברו איתנו",
    menus: [
      { href: "tel:03-9691656", label: "טלפון 03-9691656", isExternal: true },
      { href: "tel:03-5323856", label: "פקס 03-5323856", isExternal: true },
      { href: "https://wa.me/972526250707", label: "WhatsApp 052-6250707", targetBlank: true, isExternal: true },
    ],
  },
  {
    id: "2",
    title: "עלינו ועל האתר",
    menus: [
      { href: "/", hash: "about", label: "אודות" },
      { href: "/terms", label: "תקנון ותנאי שימוש" },
      { href: "/accessibility", label: "הצהרת נגישות" },
    ],
  },
  {
    id: "3",
    title: "צרו קשר",
    menus: [
      { href: "mailto:info@mydyo.co.il", label: "דוא״ל info@mydyo.co.il", isExternal: true },
      { href: "https://ul.waze.com/ul?place=ChIJMd5a30a0AhURAMhOvJdJjGo&ll=31.96721820%2C34.80266480&navigate=yes", label: "הרצל 46, ראשון לציון", targetBlank: true, isExternal: true },
      { href: "", label: "שעות פעילות:" },
      { href: "", label: "א-ה 9:00-20:00" },
      { href: "", label: "ו 9:00-14:00" },
    ],
  },
];

const Footer: React.FC = () => {

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-white dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => {

            const isLink = item.href !== "";
            if (isLink) {
              return (
                <li key={index}>
                  {(item.href !== "" && item.isExternal) ? (
                    <a
                      key={index}
                      className="text-white dark:text-neutral-300 hover:text-deepFuchsia dark:hover:text-white"
                      href={item.href}
                      target={item.targetBlank ? "_blank" : undefined}
                      rel="noopener noreferrer"
                    >
                      {item.label}
                    </a>
                  ) : (
                    <Link
                      className="text-white dark:text-neutral-300 hover:text-deepFuchsia dark:hover:text-white"
                      to={{
                        pathname: item.href || undefined,
                        hash: item.hash || undefined,
                      }}
                      target={item.targetBlank ? "_blank" : undefined}
                    >
                      {item.label}
                    </Link>
                  )}
                </li>
              )
            } else {
              return (
                <li key={index}>
                  <span className="text-white dark:text-neutral-300">{item.label}</span>
                </li>
              )
            }
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer">
      <div className="bg-darkBlue relative p-4 py-16 border-t border-neutral-200 dark:border-neutral-700">
        <div className="container grid grid-cols-1 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:gap-x-10">
          <div className="">
            <div className="col-span-2 md:col-span-1">
              <Logo />
            </div>
            <div className="col-span-2 flex items-center md:col-span-3">
              <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
            </div>
          </div>
          {widgetMenus.map(renderWidgetMenuItem)}
        </div>
      </div>
      <div className="h-[66px] bg-yankeesBlue flex items-center justify-center lg:justify-start px-1 lg:px-24">
        <div className="text-right text-white text-sm font-normal">כל הזכויות שמורות לתקשיר בע”מ @ 2025</div>
      </div>
    </div>
  );
};

export default Footer;
