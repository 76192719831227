import React, { FC } from "react";
import Button from "shared/Button/Button";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";

import useContactForm from "hooks/useContactForm";

export interface MobileContactUsProps {
  className?: string;
}

const MobileContactUs: FC<MobileContactUsProps> = ({ className }) => {

  const { values, errors, isSubmitting, isSubmitted, handleChange, handleSubmit } = useContactForm();

  return (
    <div className={`MobileContactUs bg-concreteGray rounded-[20px] border-2 border-[#f1f8ff] py-4 block ${className}`}>
      <div className="text-center text-[#09052f] text-xl font-bold">השאירו פרטים, מיד חוזרים</div>
      <form className="grid grid-cols-1 gap-6 p-5" onSubmit={handleSubmit}>
        <label className="block">
          <div className="text-right text-[#5f5f5f] text-[18px] font-normal">שם מלא</div>
          <Input
            className={`mt-1 ${errors.fullName ? "border-red-500" : ""}`}
            type="text"
            name="fullName"
            value={values.fullName}
            onChange={handleChange}
            placeholder=""
          />
          {errors.fullName && <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>}
        </label>
        <label className="block">
          <div className="text-right text-[#5f5f5f] text-[18px] font-normal">כתובת דוא״ל</div>
          <Input
            className={`mt-1 ${errors.fullName ? "border-red-500" : ""}`}
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="example@example.com"
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </label>
        <label className="block">
          <div className="text-right text-[#5f5f5f] text-[18px] font-normal">הודעה</div>
          <Textarea
            className={`mt-1 ${errors.fullName ? "border-red-500" : ""}`}
            name="description"
            value={values.description}
            onChange={handleChange}
            rows={5} />
          {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description}</p>}
        </label>
        <div className="text-center">
          <Button
            className="max-w-[254px] h-[47px] p-2.5 bg-white rounded-[50px] border-b-4 border-[#ff4e8d] justify-center items-center gap-2.5 inline-flex"
            sizeClass="py-3 px-8 sm:py-3.5 sm:px-16"
            loading={isSubmitting}>
            <div className="justify-center items-center gap-2.5 flex">
              <div className="text-center text-[#09052f] text-xl font-bold">{isSubmitting ? "" : "שלחו פרטים"}</div>
            </div>
          </Button>
          {isSubmitted && (
            <div className="mt-4 p-3 text-green-700 text-xl">
              תודה שפנית אלינו
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default MobileContactUs;
