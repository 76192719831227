import { Helmet } from "react-helmet-async";

const PageAccessibility = () => {

  return (
    <div className="nc-PageTerms pt-8 lg:pt-16 ">
      <Helmet>
        <title>הצהרת נגישות</title>
      </Helmet>

      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            הצהרת נגישות
          </h1>
        </div>
      </header>

      <div className="container rounded-xl">
        <div className="prose prose-sm sm:prose lg:prose-lg max-w-screen-md mx-auto mt-5 mb-14">

          <h3>כללי</h3>
          <p>חברתנו שואפת לספק חווית שימוש מרבית באתר לכלל הציבור לרבות ציבור בעלי המוגבלויות, השקענו מאמצים רבים במטרה לאפשר, להקל ולייעל את השימוש באתר בדגש על צרכי ציבור זה.</p>
          <p>התאמות הנגישות באתר שלנו בוצעו בהתאם לתקנה 35 בתקנות שוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות) התשע"ג 2013 ,לתקן הישראלי ת"י 5568 המבוסס על הנחיות 2.0WCAG לרמה AA ובכפוף לשינויים והתאמות שבוצעו במסמך התקן הישראלי.</p>
          <p>התאמת הנגישות נבדקה בדפדפנים המובילים כגון פיירפוקס וכרום בגירסאות האחרונות, וכלה שימוש טכנולוגיות מסייעות מסוג קורא מסך (NVDA).</p>
          <p></p>

          <h3>כיצד הונגש האתר</h3>
          <ul>
            <li>קישורים בתחילת הדף המאפשרים דילוג לתוכן.</li>
            <li>תיאור טקסטואלי לתמונות ואייקונים עבור טכנולוגיות מסייעות.</li>
            <li>כפתורי עצירה והפעלה של גלריות.</li>
            <li>הוטמעו חוקי ARIA העוזרים לפרש את תוכן האתר בצורה מדויקת וטובה יותר.</li>
            <li>הנגשת תפריטים, טפסים, היררכיית כותרות, רכיב טאבים, חלונות קופצים ועוד.</li>
            {/* <li>כדי לנווט באתר בעזרת המקלדת השתמשו בכפתור ה Tab . כל לחיצה עליו תעביר אותכם ללינק הבא. לחיצה על Shift + Tab תחזיר אותכם ללינק הקודם.</li> */}
          </ul>

          <h3>פרטי אחראי נגישות בחברה</h3>
          <ul>
            <li>שם: טום</li>
            <li>טלפון: 03-9691656</li>
            <li>אימייל: info@mydyo.co.il</li>
          </ul>

          <h3>תאריך עידכון ההצהרה</h3>
          <p>הצהרת הנגישות עודכנה לאחרונה בתאריך 01/06/2024</p>
        </div>
      </div>
    </div>
  );
};

export default PageAccessibility;
